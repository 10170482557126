import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "../css/dominique.module.css"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

const DominiqueTest = () => {
  const data = useStaticQuery(graphql`
    query {
      mof: file(name: { eq: "logo-mof-couleur" }) {
        childImageSharp {
          fixed(width: 58) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      metiers: file(name: { eq: "stars-metiers" }) {
        childImageSharp {
          fixed(width: 131) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      domi: file(name: { eq: "dominique-legris-mobile" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div id={styles.desktop} className={styles.desktop2}>
        <div className="w-75-ns flex items-center center">
          <div className="w-50-ns"></div>
          <div className={(styles.cadre += " w-50-ns")}>
            <p className={styles.titre}>
              <FormattedMessage id="index.domi.title" />
            </p>
            <p className={styles.intro}>
              <FormattedMessage id="index.domi.intro" />
            </p>
            <p className={styles.contenu}>
              <FormattedMessage id="index.domi.p1" />
              <br />
              <FormattedMessage id="index.domi.p2" />
              <br />
              <FormattedMessage id="index.domi.p3" />
            </p>
            <div className="flex flex-row-reverse items-center">
              <Img
                fixed={data.metiers.childImageSharp.fixed}
                className="ml2"
                alt="logo stars métiers"
              />
              <Img
                fixed={data.mof.childImageSharp.fixed}
                alt="logo meilleur ouvrier de france"
              />
            </div>
          </div>
        </div>
      </div>

      <div id={styles.mobile}>
        <div>
          <Img
            fluid={data.domi.childImageSharp.fluid}
            alt="Dominique le gris"
          />
        </div>
        <div className={styles.cadre}>
          <p className={styles.titre}>
            <FormattedMessage id="index.domi.title" />
          </p>
          <p className={styles.intro}>
            <FormattedMessage id="index.domi.intro" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="index.domi.p1" />
            <br />
            <FormattedMessage id="index.domi.p2" />
            <br />
            <FormattedMessage id="index.domi.p3" />
          </p>
          <div className="flex flex-row-reverse items-center">
            <Img
              fixed={data.metiers.childImageSharp.fixed}
              className="ml2"
              alt="logo stars métiers"
            />
            <Img
              fixed={data.mof.childImageSharp.fixed}
              alt="logo meilleur ouvrier de france"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DominiqueTest
