import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import Header from "../components/layout/js/header"
import Overlay from "../components/layout/js/overlay"
import Visuel from "../components/index/js/visuel"
//import Recherche from "../components/layout/js/search"
import Categories from "../components/index/js/categories"
import Tendances from "../components/index/js/tendances"
import Dominique from "../components/index/js/dominique"
import Galerie from "../components/index/js/galerieLoadable"
import Contacter from "../components/layout/js/contacter"
import Footer from "../components/layout/js/footer"
import Copyrights from "../components/layout/js/copyrights"

import { ParallaxProvider } from "react-scroll-parallax"
import {graphql, Link, useStaticQuery} from "gatsby"
import Img from "gatsby-image";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
      query {
          artDeco: file(name: { eq: "index-ArtDeco" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          lloyd: file(name: { eq: "frank-lloyd" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)
  const intl = useIntl()
  const structuredDataHome = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Quel est l'essor du vitrail art deco ?",
        acceptedAnswer: {
          "@type": "Answer",
          text:
            "C'est dans le début du 20 ème siècle et plus précisément à partir des années 1920 que l'art déco entre dans sa période faste. Le vitrail art deco vient rompre totalement les codes de l' art-nouveau.",
        },
      },
      {
        "@type": "Question",
        name: "Le vitrail art deco : Est-ce un art décoratif à part entière ?",
        acceptedAnswer: {
          "@type": "Answer",
          text:
            "Le vitrail contemporain constitue aujourd'hui un véritable élément de décoration qui transforme un intérieur par son design original et permet d'illuminer une pièce avec beaucoup de subtilité.",
        },
      },
      {
        "@type": "Question",
        name:
          "Où peut s'inviter le vitrail art deco dans la décoration intérieure ?",
        acceptedAnswer: {
          "@type": "Answer",
          text:
            "Les designers d'intérieur ou les architectes ont bien compris l'importance de l'intervention d'un artisan verrier pour offrir des décorations intérieures qui s'intégreront parfaitement dans les volumes et l'ambiance des espaces de vie contemporains.",
        },
      },
    ],
  }
  return (
    <>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "index.seo.title" })}
        description="Atelier de création de vitraux ART DECO sur mesures et décors en double vitrage. Découvrez l’ensemble de nos collections pour portes d’entrée, fenêtres et vérandas."
        structuredData={structuredDataHome}
      />
      <ParallaxProvider>
        <Header />
        <Overlay />
        <Visuel />
        <div className="w-75-ns center first ph3 textFadeIn">
          <h1 className="titre2">
            Vitrail art deco
          </h1>
          <p>
            Jusqu'à la fin du 19 ème siècle, les <strong>vitraux</strong>{" "}
            n'avaient pour seul objectif que la décoration de l'architecture des
            églises et autres monuments religieux. C'était le style gothique qui
            prédominait avec de grandes fresques représentant des scènes
            bibliques ou le récit de scènes de la vie quotidienne au moyen-âge.
            L'art gothique permet aux monuments historiques tels que les
            cathédrales et les églises de s'habiller de vitraux majestueux à
            l'instar des cathédrales de Reims, Paris, Chartres ou Metz. Du
            moyen-âge jusqu'à la fin du 19 ème siècle,{" "}
            <strong>l'art du vitrail sculptural</strong> garde un rôle de
            pédagogie pour expliquer les scènes bibliques à la population
            croyante ne sachant pas lire.
          </p>
          <p>
            C'est avec la naissance du mouvement artistique de{" "}
            <a href="#art-nouveau">
              <strong>"l'art nouveau"</strong>
            </a>{" "}
            que le maître-verrier se met à créer des œuvres destinées à orner
            des maisons cossues, des châteaux ou des maisons bourgeoises. Les
            beaux-arts s'invitaient alors dans les foyers les plus aisés pour
            devenir des décorations ornementales. L' art du vitrail entrait
            alors dans un nouvel élan et devenait un élément purement décoratif.
          </p>
        </div>
        {/* <Recherche /> */}
        <Galerie />
        <Categories />
        <Tendances />
        <Dominique />
        <div id="art-nouveau"></div>
        <div className="w-75-ns center first ph3 homepage">
          <h2>
            <b>
              De la belle époque de l'art-nouveau aux créations art-déco contemporaines
            </b>
          </h2>
          <div className="flex flex-wrap mb6">
            <div className="w-100 w-50-l">
              <Img
                className="w-80"
                fluid={data.artDeco.childImageSharp.fluid}
                alt="Vitrail Art Déco"
              />
            </div>
            <div className="w-50-l pl3-ns lettre">
              <p>
                L'art-nouveau conduit les artistes à s'inspirer de la nature, de la
                flore et de la faune ou encore de la féminité. C'est ainsi que les
                maîtres verriers vont créer des ensembles de verres voués au
                décoratif mêlant des motifs floraux, des représentations
                animalières, des arabesques ou des silhouettes féminines
                affriolantes. Le peintre se permet des mélanges de couleurs vives et
                les représentations graphiques se veulent fidèles au modèle ou, au
                contraire, très stylisées. L'art-nouveau fait naître les prémices de{" "}
                <strong>l'art-déco dans l' art du vitrail</strong>.
              </p>
              <p>
                L'architecture légèrement naïve et chargée des œuvres art-nouveau
                enjoint les ateliers de création à d'avantage de légèreté.
                L'artisan verrier devient créateur d' arts décoratifs avec un style
                plus épuré, dotés de lignes géométriques plus rigoureuses.{" "}
                <strong>Le vitrail art deco se pare</strong> de pans coupés,
                d'angles bruts et de sections vives. Les couleurs demeurent, elles
                aussi, d'une palette très restreinte tournant sur des tons neutres
                comme le gris, le beige, le noir ou le jaune.
              </p>
            </div>
          </div>
          <h3><b>Quel est l'essor du vitrail art deco ? </b></h3>
          <p>
            C'est dans le début du 20 ème siècle et plus précisément à partir
            des années 1920 que l'art déco entre dans sa période faste.{" "}
            <strong>Le vitrail art deco</strong> vient rompre totalement les
            codes de l' art- nouveau. L' artisan verrier aborde des thèmes
            décoratifs qui deviennent plus abstraits et la stylisation filtre
            désormais les principes abordés. A cette époque, les sujets
            représentés tendent à mettre en valeur des notions de progrès,
            promesse de vie meilleure. La sobriété des nuances voit le peintre
            ne plus utiliser de couleurs vives au profit d'évanescence plus
            nuancées. Les œuvres de verres s'invitent alors de plus en plus dans
            divers bâtiments publics. L' artisanat des maîtres verriers se
            voient confier la réalisation de <strong>plafonds de verres</strong>{" "}
            dans des grands magasins ou des restaurants. Les gares s'ornent
            d'immenses baies vitrées et le terme "vitrail", généralement
            synonyme d'ornements pour les monuments historiques ou les édifices
            religieux, est remplacé par celui de "verrière".
          </p>
          <h3>
            <b>Le vitrail art deco : Est-ce un art décoratif à part entière ?{" "}</b>
          </h3>
          <p>
            Le <strong>vitrail contemporain</strong> constitue aujourd'hui un
            véritable élément de décoration qui transforme un intérieur par son
            design original et permet d'illuminer une pièce avec beaucoup de
            subtilité. Un architecte va utiliser une œuvre de verre pour
            cloisonner une pièce sans incidence sur la{" "}
            <strong>luminosité</strong> ou pour masquer une vue indélicate.
          </p>
          <p>
            Les maîtres verriers, à l'image de <strong>Fany Glass</strong>,
            grâce à des techniques diverses, sont capables de proposer des{" "}
            <strong>créations artisanales sur mesure</strong> pour des projets
            multiples d' arts décoratifs. Ainsi, l'atelier Fany Glass propose
            des créations dignes des beaux-arts comme un grand vitrail qui
            viendra habiller les intérieurs d'un hall d'immeuble, des bureaux ou
            des verrières et des vérandas de grandes dimensions. Notre savoir
            faire est reconnu par les bâtiments de France nous permettant
            d'intervenir sur les édifices et monuments classés du patrimoine
            national.
          </p>
          <p>
            De façon plus intime, <strong>l'atelier</strong> Fany Glass propose
            à travers ses créations art déco une multitude de façons de mettre
            en valeur un intérieur ou une vue extérieure avec des éléments
            contemporains. Avec des motifs géométriques inspirés et des formes
            abstraites, le <strong>vitrail art deco</strong> devient une
            véritable pièce d' art décoratif dans votre habitation, le rendant
            unique et original. Le style art déco s'invite alors dans des
            créations multiples comme celles de Louis Comfort Tiffany qui créera
            des abat-jours réunissant un patchwork de verres grâce à la
            technique d'assemblage à l’étain.
          </p>
          <h3>
            <b>
              Où peut s'inviter le vitrail art deco dans la décoration intérieure ?
            </b>
          </h3>
          <div className="flex flex-wrap mb6">
            <div className="w-50-l pl3-ns lettreA">
              <p>
                <strong>Les designers d'intérieur ou les architectes</strong> ont
                bien compris l'importance de l'intervention d'un artisan verrier
                pour offrir des décorations intérieures qui s'intégreront
                parfaitement dans les volumes et l'ambiance des espaces de vie
                contemporains. Outre le fait de renforcer ou de donner une réelle
                valeur ajoutée pour la qualité de la luminosité d'une pièce, un
                grand vitrail peut devenir simplement esthétique. Mettre en relief
                la colorimétrie d'une atmosphère ou transformer une pièce épurée en
                espace contemporain par une touche de gaieté, le vitrail
                contemporain se prête à toutes les envies.
              </p>
              <p>
                Un <strong>vitrail art deco</strong> réalisé par un maitre verrier
                possède une durée de vie de plusieurs dizaines d'années, il est donc
                important de s'accorder les conseils d'un architecte ou d'un
                professionnel de la décoration Plutôt que de faire son choix. L'art
                déco reste tout de même un style intemporel qui permet de se doter
                d'une pièce d' artisanat sans avoir peur que ces éléments décoratifs
                ne deviennent plus au goût du jour.
              </p>
              
              <p>
                Utilisé comme verrière ou à la place de vitres traditionnelles,
                c'est à la tombée de la nuit que l'art du vitrail donne tout son
                potentiel et son originalité à une maison ou à un appartement. La
                vue à travers une pièce de verre de nuit et de l'extérieur donne
                toute son ampleur et une touche d'originalité dans l'aspect de votre
                demeure et de vos intérieurs. Un vitrail art déco garantit
                d'apporter une certaine sophistication et une symétrie qui permet de
                booster l'allure et le décor d'une pièce.
              </p>
              </div>
                <div className="w-100 w-50-l ">
                  <Img
                    className="w-100 w-60-l right"
                    fluid={data.lloyd.childImageSharp.fluid}
                    alt="Vitrail Franck Lloyd"
                  />
                </div>
          </div>

          <p>
            Que ce soit pour rehausser le caractère d'une{" "}
            <Link to="/portes-entrees/#vitrail-porte-entree">
              porte d'entrée
            </Link>{" "}
            , d'une fenêtre ou pour donner un design unique à une véranda, les
            créations de <strong>vitraux art déco</strong> de notre atelier Fany
            Glass permettent de répondre à toutes les demandes et toutes les
            envies. Faire appel à notre atelier d'artisan verrier c'est vous
            assurer d'une expérience de 25 ans dans le développement de
            techniques de fabrication pour des pièces de verres uniques
            s'adaptant à toutes les menuiseries et installations modernes. Un{" "}
            <strong>vitrail art deco Fany Glass</strong> est un élément de décor
            unique que nous concevons dans les règles de l'art, aux côtés de nos
            clients.
          </p>
          <p>
            Grâce à une technique de fabrication imaginée par notre
            maître-verrier, qui consiste en l'insertion d'une trame de plomb à
            l'intérieur et à l'extérieur d'un vitrage, les coûts ont été
            optimisés. Il devient donc abordable d'ajouter à sa décoration
            intérieure un vitrail art deco unique et original. L' art du vitrail
            est désormais plus accessible et nombreux particuliers sont conquis
            par le <strong>style art déco</strong> de nos vitraux.
          </p>
        </div>
        <Contacter />
        <Footer />
        <Copyrights />
      </ParallaxProvider>
    </>
  )
}

export default IndexPage
