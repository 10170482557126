import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "../css/categories.module.css"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

import Img from "gatsby-image"

const Categories = () => {
  const data = useStaticQuery(graphql`
    query {
      portes: file(name: { eq: "categories-portes-entrees" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fenetres: file(name: { eq: "categories-fenetres" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      verandas: file(name: { eq: "categories-verandas" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id="categories" className=" center w-75-ns mv6 ph3">
      <p className="titre2">
        <FormattedMessage id="index.categories.title" />
      </p>
      <div className="flex flex-column flex-row-ns justify-center items-center">
        <div className={(styles.overlay += " ")}>
          <Img
            fluid={data.portes.childImageSharp.fluid}
            className={styles.overlayImage}
            alt="portes d'entrées"
          />
          <p className={styles.overlayText}>
            <FormattedMessage id="index.categories.front-doors" />
          </p>
          <Link
            to="/portes-entrees"
            className={(styles.overlayButton += " button-primary")}
          >
            <FormattedMessage id="index.categories.button" />
          </Link>
        </div>
        <div className={(styles.overlay += " mv3 mh2-ns")}>
          <Img fluid={data.fenetres.childImageSharp.fluid} alt="fenêtres" />
          <p className={styles.overlayText}>
            <FormattedMessage id="index.categories.windows" />
          </p>
          <Link
            to="/fenetres"
            className={(styles.overlayButton += " button-primary")}
          >
            <FormattedMessage id="index.categories.button" />
          </Link>
        </div>
        <div className={(styles.overlay += " ")}>
          <Img fluid={data.verandas.childImageSharp.fluid} alt="verandas" />
          <p className={styles.overlayText}>
            <FormattedMessage id="index.categories.verandas" />
          </p>
          <Link
            to="/verandas"
            className={(styles.overlayButton += " button-primary")}
          >
            <FormattedMessage id="index.categories.button" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Categories
