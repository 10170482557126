import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "../css/visuel.module.css"
import chevron from "../../../images/chevron.svg"
import Img from "gatsby-image"
import { ParallaxBanner } from "react-scroll-parallax"
import banner from "../../../static/img/banner-hp.jpg"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

const Visuel = () => {
  const data = useStaticQuery(graphql`
    query {
      fra: file(name: { eq: "fra" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      banner: file(name: { eq: "banner-hp" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)

  return (
    <>
      <ParallaxBanner
        className="first"
        layers={[
          {
            image: banner,
            amount: 0.5,
          },
        ]}
        style={{
          height: "100%",
        }}
      >
        <div className="flex flex-column items-center center pv4 w-75-ns ph3">
          <span className={(styles.accroche += " tc mt2")}>
            <FormattedMessage id="index.visuel.title" />
          </span>
          <p className={(styles.soustitre += " tc")}>
            <FormattedMessage id="index.visuel.subtitle" />
          </p>

          <Img fixed={data.fra.childImageSharp.fixed} alt="bleu blanc rouge" />

          <p className={(styles.mof += " tc")}>
            <FormattedMessage id="index.visuel.mof" />
          </p>
          <Link to="/decouvrir" className={(styles.button += " mv3")}>
            <FormattedMessage id="index.visuel.button" />
          </Link>
          <Link to="#collections" className={styles.chevron}>
            <img src={chevron} alt="chevron" />
          </Link>
        </div>
      </ParallaxBanner>
      <div id="collections"></div>
    </>
  )
}

export default Visuel
