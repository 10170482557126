import React, { useState } from "react"
import styles from "../css/tendances.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

const Categories = () => {
  const data = useStaticQuery(graphql`
    query {
      molitor: file(name: { eq: "actu-villa-molitor" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      art: file(name: { eq: "actu-art-deco" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      verriere: file(name: { eq: "actu-verriere" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [src, setSrc] = useState(data.molitor.childImageSharp.fluid)
  const [alt, setAlt] = useState("Vitrail déco villa")
  const [link, setLink] = useState("actualite-villa")

  const handleOnMouseOver = e => {
    const titreMolitor = document.getElementById("molitor")
    const titreArt = document.getElementById("art")
    const titreVerriere = document.getElementById("verriere")

    e.target.style.textDecoration = "underline"
    if (e.target.getAttribute("id") === "molitor") {
      setSrc(data.molitor.childImageSharp.fluid)
      setAlt("Vitrail déco villa")
      setLink("/actualite-villa")

      titreArt.style.textDecoration = "none"
      titreVerriere.style.textDecoration = "none"
    } else if (e.target.getAttribute("id") === "art") {
      setSrc(data.art.childImageSharp.fluid)
      setAlt("Vitrail art déco")
      setLink("/actualite-art-deco")

      titreMolitor.style.textDecoration = "none"
      titreVerriere.style.textDecoration = "none"
    } else if (e.target.getAttribute("id") === "verriere") {
      setSrc(data.verriere.childImageSharp.fluid)
      setAlt("Verrière artiste")
      setLink("/actualite-verriere")

      titreMolitor.style.textDecoration = "none"
      titreArt.style.textDecoration = "none"
    }
  }

  return (
    <section
      id="tendances"
      className={(styles.lettre += " flex flex-column center w-75-ns ph3 mv6")}
    >
      <p className="titre2 self-end">
        <FormattedMessage id="index.news.title" />
      </p>

      <div className="flex flex-column flex-row-ns justify-center items-center">
        <div className="center w-40-ns w-100">
          <Link
            to="/actualite-villa"
            id="molitor"
            onMouseOver={handleOnMouseOver}
            className={(styles.titre += " tc")}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            <FormattedMessage id="index.news.molitor" />
          </Link>
          <Link
            to="/actualite-art-deco"
            id="art"
            onMouseOver={handleOnMouseOver}
            className={(styles.titre += " tc ma5-ns")}
            style={{ cursor: "pointer" }}
          >
            <FormattedMessage id="index.news.art-deco" />
          </Link>
          <Link
            to="/actualite-verriere"
            id="verriere"
            onMouseOver={handleOnMouseOver}
            className={(styles.titre += " tc")}
            style={{ cursor: "pointer" }}
          >
            <FormattedMessage id="index.news.window" />
          </Link>
        </div>

        <div className={(styles.overlayMolitor += " w-60-ns w-100 items-end")}>
          <Link to="/tendances">
            <div>
              <Img id="contient" fluid={src} alt={alt} />
              <Link
                to={link}
                className={(styles.overlayButton += " button-primary")}
              >
                <FormattedMessage id="index.news.button" />
              </Link>
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Categories
