import React from "react"
import Loadable from "react-loadable"

const Galerie = Loadable({
  loader: () => import("./galerie"),
  loading() {
    return <div></div>
  },
})

export default () => (
  <div>
    <Galerie />
  </div>
)
